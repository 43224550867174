import { extend } from 'underscore'
import Backbone from 'backbone'
import Navigation from 'models/modules/navigation'

export default class BaseCollection extends Backbone.Collection {
  initialize (models, options = {}) {
    extend(this, Navigation)
    this.fetched = false
    this.fetching = false
    this.parentInstance = options.parentInstance

    this.setIdAttribute(models)
    return super.initialize(models, options)
  }

  _prepareModel (attrs, options) {
    const model = super._prepareModel(attrs, options)

    this.parentInstance?.relateTo(model)
    return model
  }

  number (problem) {
    return this.indexOf(this._find(problem)) + 1
  }

  fetch (options = {}) {
    this.fetching = true

    const success = options.success
    const error = options.error

    options.success = (collection, resp, options) => {
      collection.fetching = false
      collection.fetched = true
      if (success) success(collection, resp, options)
    }
    options.error = (collection, resp, options) => {
      collection.fetching = false
      collection.fetched = false
      if (error) error(collection, resp, options)
    }

    return super.fetch(options)
  }

  findByUid (uid) {
    return this.find((model) => model.uid() == uid)
  }

  // this is a hack to ensure Backbone does not throw an error trying to
  // access idAttribute on the model function above and throw an error.
  setIdAttribute (models) {
    if (models && Array.isArray(models)) {
      models.forEach((model) => {
        if (!this._isModel(model)) {
          model.idAttribute = 'id'
        }
      })
    }
  }
}
