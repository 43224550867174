import 'whatwg-fetch'
import ActionCable from 'actioncable'
import Rails from '@rails/ujs'
import('stylesheets/application')

window.Cable = ActionCable.createConsumer()
Rails.start()
// require("@rails/activestorage").start()

require('init/timezone-cookie')()
require('init/plugins')
require('init/backbone')
require('init/bootstrap')
