// https://inopinatus.org/2019/09/14/webpacker-jquery-and-jquery-plugins/
// all the jquery and plugins
require('jquery-ui/ui/widget')
require('jquery-ui/ui/position')
require('jquery-ui/ui/widgets/mouse')
require('jquery-ui/ui/widgets/draggable')
require('jquery-ui/ui/widgets/droppable')
require('jquery-ui/ui/widgets/resizable')
require('jquery-ui/ui/widgets/selectable')
require('jquery-ui/ui/widgets/autocomplete')
require('jquery-ui/ui/widgets/menu')
require('jquery-ui/ui/widgets/progressbar')
require('jquery-ui/ui/widgets/slider')
require('jquery-ui/ui/widgets/tooltip')
require('jquery-ui/ui/effect')

require('jquery-ui-touch-punch-0.2.3')
require('jquery.filedownload')
require('fine-uploader')
require('jquery.nestable')

// plugin must come before countdown (2013)
require('jquery.plugin')
require('jquery.countdown-2.1.0')

require('jquery.scrollTo')
require('jquery.timeago')

require('mailgun_validator')

require('mathquill')
require('knowl')
require('webwork_scaffold')
require('switch-checkbox')
