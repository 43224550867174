/* global $ */
import { defer } from 'underscore'
import Backbone from 'backbone'
import { typeset } from 'helpers/mathjax'
import BaseView from 'views/base'
import Cookie from 'models/cookie'

export default class LeftSheet extends BaseView {
  events () {
    return {
      'click .toggle-button, .secondary-more-button': 'onToggleClicked',
      'click .btn-sheet': 'onSheetButtonClicked',
      'click .btn-back': 'onBackClicked',
    }
  }

  initialize (options) {
    this.cookie = new Cookie({ namespace: 'guest' })
    this.expanded = this.cookie.get('leftSheet.expanded', true)
    this.listenTo(Backbone.history, 'navigate', this.onNavigate.bind(this))
    this.listenTo(Backbone.history, 'route', this.onRoute.bind(this))
  }

  buttonIcon (expanded) {
    return expanded ? 'fa-chevron-left' : 'fa-chevron-right'
  }

  shouldActivateTabOnRoute () {
    return $('.primary-tabs').data('activate-tab') !== false
  }

  render () {
    this.$('.contextual-navigation').removeClass('locked')
    $('main').removeClass('locked')
    this.initTabbable()
    this.setExpanded()
    typeset(this.el)

    return this
  }

  attach (model, tabs) {
    if (this.mode1) {
      this.stopListening(this.model)
    }
    this.model = model
    this.tabs = tabs

    if (this.model) {
      this.listenTo(this.model, 'change:name', this.onNameChange)
    }
  }

  setExpanded () {
    this.$('.toggle-button').attr('aria-expanded', this.expanded)
    this.$('.toggle-button span')
      .removeClass(this.buttonIcon(!this.expanded))
      .addClass(this.buttonIcon(this.expanded))
    this.$('.contextual-navigation').toggleClass('expanded', this.expanded)
    $('main').toggleClass('expanded', this.expanded)
  }

  showSecondaryTabs (tabs) {
    this.$('.sheet-tab-slider').addClass('slide-left')
    this.$('.sidebar-back').removeClass('d-none').addClass('d-flex')
    this.notTabbable(this.$('.primary-tabs'))
    this.tabbable($(tabs))
    this.hideSecondaryTabs()
    $(tabs).addClass('show')
  }

  showPrimaryTabs () {
    this.$('.sheet-tab-slider').removeClass('slide-left')
    this.$('.sidebar-back').removeClass('d-flex').addClass('d-none')
    this.notTabbable(this.$('.secondary-tabs'))
    this.tabbable(this.$('.primary-tabs'))
  }

  hideSecondaryTabs () {
    this.$('.secondary-tabs').removeClass('show')
  }

  notTabbable ($el) {
    $el.css('visibility', 'hidden')
    $el.removeClass('active')
    $el.find('a').removeClass('active')
  }

  tabbable ($el) {
    $el.css('visibility', 'visible')
  }

  initTabbable () {
    if (this.$('.sheet-tab-slider').hasClass('slide-left')) {
      this.showSecondaryTabs(this.$('.secondary-tabs.show'))
    } else {
      this.showPrimaryTabs()
    }
  }

  activateFirstTab (tabs) {
    $(tabs).find('a').first().tab('show')
    defer(() => {
      $(tabs).find('a').first().focus()
    })
  }

  handleRoute () {
    let path = Backbone.history.fragment.split('?')[0]
    path = path.split('/')
    const route = path[path.length - 1]

    if (/^[a-f\d]{24}$/i.test(route)) {
      // hacky way to determine if our route is a default one (ending in an ID)
      const otherItems = $('.primary-tabs').find('.nav-item:not(:first-child)')
      otherItems.removeClass('active')
      otherItems.find('a').removeClass('active')

      if (this.shouldActivateTabOnRoute()) {
        this.activateFirstTab('.primary-tabs')
      }
    } else {
      const segments = route.split('-')

      if (segments.length === 1) {
        this.showPrimaryTabs()
      } else {
        const tabs = $(`#${segments[segments.length - 1]}-tabs`)
        if (tabs.get(0)) {
          this.showSecondaryTabs(tabs)
        }
      }
    }
  }

  //
  // event handlers
  //
  onToggleClicked () {
    this.expanded = !this.expanded
    this.cookie.set('leftSheet.expanded', this.expanded)
    this.setExpanded()
  }

  onSheetButtonClicked (e) {
    const tabs = $(e.currentTarget).data('target')
    this.showSecondaryTabs(tabs)
    this.activateFirstTab(tabs)
    defer(() => this.$('.btn-back').focus())
  }

  onBackClicked () {
    this.showPrimaryTabs()
    this.activateFirstTab(this.$('.primary-tabs'))
  }

  onNameChange () {
    const navLink = this.$('.nav-link-expanded .nav-link')

    navLink.html(this.model.get('name'))
    typeset(navLink.get(0))
  }

  onNavigate (path) {
    this.handleRoute()
  }

  onRoute (router, name, args) {
    if (this.initialized) {
      this.handleRoute()
    }
    this.initialized = true
  }
}
