export default {
  next (model) {
    const found = this._find(model)
    if (found) {
      return this.at(this.indexOf(found) + 1)
    } else {
      return null
    }
  },

  previous (model) {
    const idx = this.indexOf(this._find(model))
    if (idx > 0) {
      return this.at(idx - 1)
    } else {
      return null
    }
  },

  _find (model) {
    return this.findWhere({ id: model.id })
  },
}
