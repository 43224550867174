/* global $ */
import { defaults, throttle, defer } from 'underscore'
import Backbone from 'backbone'
import ally from '@edfinity/ally/src/ally'
import disabled from '@edfinity/ally/src/maintain/disabled'
import hidden from '@edfinity/ally/src/maintain/hidden'
import tabFocus from '@edfinity/ally/src/maintain/tab-focus'
import { isLargeDisplay } from 'helpers/view'
import BaseView from 'views/base'

export default class LeftDrawer extends BaseView {
  events () {
    return {
      'click .btn-drawer': 'onDrawerButtonClicked',
      'click .drawer-back': 'onDrawerBackClicked',
      keyup: 'onKeyup',
    }
  }

  initialize (options) {
    super.initialize(options)
    defaults(this.options, { topDrawer: '#top-drawer' })
    this.topDrawer = this.currentDrawer = $(this.options.topDrawer)

    $(window).resize(throttle(this.onResize.bind(this), 100))
    this.listenTo(Backbone.history, 'route navigate', this.onRoute.bind(this))
  }

  render () {
    this.$el.css({ visibility: 'hidden' })
    this.$('.drawer-secondary .sheet-tabs').prepend(this.backEl())
    $('#left-drawer-toggle').click(this.onDrawerToggleClicked.bind(this))
    $('#drawer-mask').click(this.onDrawerMaskClicked.bind(this))
    return this
  }

  show () {
    this.open = true
    this.disabledHandle = disabled({ filter: this.el })
    this.hiddenHandle = hidden({ filter: this.el })
    if (this.initialized) {
      this.setTabFocus(this.el)
    }

    this.$el.css('visibility', 'visible')
    this.$el.attr('aria-hidden', false)
    $('#root').addClass('left-expanded')
    this.showInitialDrawer()
  }

  hide () {
    this.open = false
    this.$el.css('visibility', 'hidden')
    this.$el.attr('aria-hidden', true)
    $('#root').removeClass('left-expanded')

    this.disabledHandle?.disengage()
    this.tabHandle?.disengage()
    this.hiddenHandle?.disengage()
  }

  showSecondaryDrawer (drawer) {
    this.topDrawer.addClass('hide-left')
    this.showDrawer($(drawer))
    this.$('#drawer-back-to-top').find('.drawer-top').addClass('show')
    this.$('.drawer-title').html($(drawer).data('name') || 'Back')
    $('.drawer-back').focus()
  }

  showTopDrawer () {
    this.$('#drawer-back-to-top').find('.drawer-top').removeClass('show')
    this.topDrawer.removeClass('hide-left')
    this.showDrawer(this.topDrawer)
  }

  showDrawer (drawer) {
    this.currentDrawer = drawer
    this.hideDrawers(drawer)
    drawer.addClass('show')
    drawer.find('.nav-link').removeAttr('tabindex')
    if (this.initialized) {
      this.setInitialFocus(drawer)
    }
    this.initialized = true
  }

  showInitialDrawer () {
    if (this.topDrawer.hasClass('hide-left')) {
      this.showSecondaryDrawer(this.$('.drawer-secondary.show'))
    } else {
      this.showTopDrawer()
    }
  }

  hideDrawers (excludeDrawer) {
    this.$('.drawer').removeClass('show')
    this.$('.drawer').find('.nav-link').attr('tabindex', -1)

    this.$('.drawer > .sheet-tabs').each(function () {
      if (!$(this).parent().is(excludeDrawer)) {
        $(this).find('.nav-item').removeClass('active')
        $(this).find('.nav-link').removeClass('active')
      }
    })
  }

  setTabFocus (el) {
    this.tabHandle?.disengage()
    this.tabHandle = tabFocus({ context: el, includeContext: true })
  }

  showFirstTab (tabs) {
    $(tabs).find('a').first().tab('show')
    defer(() => {
      $(tabs).find('a').first().focus()
    })
  }

  setInitialFocus (drawer) {
    const focusEl =
      drawer.find('a.nav-link.active').get(0) ||
      drawer.data('target') ||
      drawer.find('a.nav-link:first')

    if (focusEl && this.$el.css('visibility') !== 'hidden') {
      // hacky!
      this.setTabFocus(drawer)
      ally.when.visibleArea({
        context: focusEl,
        callback (element) {
          $(focusEl).focus()
        },
      })
    }
  }

  backEl () {
    return $(`<div class="nav-item d-flex my-3"> \
<button \
  type="button" \
  class="btn-reset nav-link d-flex flex-grow-1 align-items-center drawer-back" \
  role="tab"> \
<span class="fa fa-chevron-circle-left"></span> \
<span class="ml-2 drawer-title no-selection"></span> \
</button> \
</div>`)
  }

  //
  // event handlers
  //
  onDrawerToggleClicked (e) {
    this.show()
  }

  onDrawerMaskClicked (e) {
    if (this.open) {
      this.hide()
    }
  }

  onDrawerButtonClicked (e) {
    const tabs = $(e.currentTarget).data('target')
    this.showSecondaryDrawer(tabs)
    this.showFirstTab(tabs)
  }

  onDrawerBackClicked (e) {
    this.showTopDrawer()
  }

  onKeyup (e) {
    if (e.which === 27) {
      this.hide()
    }
  }

  onResize () {
    const isLarge = isLargeDisplay()
    if (this.open && isLarge) {
      this.hide()
    }
  }

  onRoute () {
    let path = Backbone.history.fragment.split('?')[0]
    path = path.split('-')
    if (path.length === 1) {
      this.showTopDrawer()
    } else {
      const drawer = $(`#${path[1]}-drawer`)
      if (drawer.get(0)) {
        this.showSecondaryDrawer(drawer)
      }
    }
  }
}
