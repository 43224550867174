/* global _E, Cookies */
import { defaults } from 'underscore'

export default class Cookie {
  constructor (options = {}) {
    const defaultValues = { path: '/', domain: _E.cookieDomain, expires: 7 }
    // eslint-disable-next-line camelcase
    if (_E.currentUser?.same_site) {
      defaultValues.sameSite = 'none'
      defaultValues.secure = true
      defaultValues.partitioned = true
    }
    Cookies.defaults = defaultValues

    this.options = options
    defaults(this.options, {
      namespace: 'ed.preferences',
      save: true,
    })
    this.map = JSON.parse(Cookies.get(this.options.namespace) || '{}')
  }

  get (key, defaultValue = false) {
    const value = this.map[key]
    if (value != null) {
      return value
    } else {
      return defaultValue
    }
  }

  set (key, value) {
    this.map[key] = value
    if (this.options.save) {
      Cookies.set(this.options.namespace, this.map)
    }
  }

  remove () {
    Cookies.remove(this.options.namespace, {
      path: '/',
      domain: _E.cookieDomain,
    })
  }
}
