import { clone, includes } from 'underscore'
import Backbone from 'backbone'
import BaseModel from 'models/base'
import User from 'models/user'

export default class Product extends BaseModel {
  parentName () {
    return 'product'
  }

  url () {
    if (this.id) {
      return `/products/${this.id}`
    } else {
      return '/products'
    }
  }

  userType () {
    if (this.get('individual_license')) {
      return 'student'
    } else {
      return 'educator'
    }
  }

  authorNames () {
    return (
      this.get('institution') ||
      this.authors
        .slice(0, 2)
        .map((author) => author.get('full_name'))
        .join(', ') ||
      'Edfinity'
    )
  }

  isAdaptive () {
    return includes(this.get('hidden_tags'), 'adaptive')
  }

  toJSON () {
    const attrs = clone(this.attributes)
    const authorIds = attrs.author_ids

    if (typeof authorIds === 'string') {
      attrs.author_ids = authorIds.split(',')
    }
    return attrs
  }

  parse (resp, options) {
    super.parse(resp, options)
    this.related_products = this.hasMany(
      'related_products',
      this.constructor,
      resp
    )
    this.paired_product = this.hasOne('paired_product', this.constructor, resp)
    this.authors =
      this.hasMany('authors', User, resp) || new Backbone.Collection()
    return resp
  }

  copy (name, callback) {
    return this.command(`/products/${this.id}/copy`, {
      data: { name },
      success: (model, resp, options) => {
        const copy = new Product(resp, { parse: true })
        return callback?.(copy, resp, options)
      },
    })
  }
}
